import { creatContextStore } from './base';


export interface AppInfo {
  Authenticated?: boolean; 
  Authenticating?: boolean;
};


const initialState = {
  Authenticated: false,
  Authenticating: false,
};

const [ Provider, useState, useSetState, useMergeState ] = creatContextStore<AppInfo>(initialState);

export {
  Provider as AppContextProvider,
  useState as useAppState,
  useSetState as useSetAppState,
  useMergeState as useMergeAppState,
};
