import classNames from "classnames";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import hamburgerIcon from "../../assets/svgs/hamburgerMenu.svg"
import closeIcon from "../../assets/images/pngs/closeIcon.png"


// Header for all pages
// Dumb component, so use stateless Function declaration
// no props
const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  
  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <header>
      <div className={styles.headerIndex}>
        <Link className="" to="/">Home</Link>  ○
        <Link className="" to="/WhatWeDo">What We Do</Link>  ○
        <Link className="" to="/WhoWeAre">Who We Are</Link>  ○
        <Link className="" to="/HowWeDoIt">What We've Done</Link>  ○
        <Link className="" to="/Careers">Careers</Link>
      </div>

      <div className={styles.mobileOnly}>
        <div className={styles.mobileNav}>
          <img onClick={toggleNav} className={toggleMenu === false ? styles.hamburgerIcon : styles.inactive} src={hamburgerIcon} alt="hamburger-menu-icon" />
          <img onClick={toggleNav} className={toggleMenu === true ? styles.closeIcon : styles.inactive} src={closeIcon} alt="hamburger-menu-close-icon" />
        </div>
        <div className={classNames(toggleMenu === true ? styles.active : styles.navItems)}>
          <Link className={styles.mobileLink} to="/">Home</Link>
          <Link className={styles.mobileLink} to="/WhatWeDo">What We Do</Link>
          <Link className={styles.mobileLink} to="/WhoWeAre">Who We Are</Link>
          <Link className={styles.mobileLink} to="/HowWeDoIt">What We've Done</Link>
          <Link className={styles.mobileLink} to="/Careers">Careers</Link>
        </div>
      </div>


    </header>

  );
};

export default Header;
