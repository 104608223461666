import Footer from "Layout/Footer";
import Header from "Layout/Header";
import styles from "./WhatWeDo.module.scss";
import ollonLogo from "../../assets/svgs/ollon_svg.svg"
import productDevLogo from "../../assets/svgs/lightbulb-icon.svg"
import appRescueLogo from "../../assets/svgs/stethoscope-icon.svg"
import extendingTeamsLogo from "../../assets/svgs/group-icon.svg"
import mentoringTeamsLogo from "../../assets/svgs/persons-icon.svg"
import devOpsLogo from "../../assets/svgs/gear-icon.svg"
import securityLogo from "../../assets/svgs/shield-and-checkmark-icon.svg"
import classNames from "classnames";
import { Link } from "react-router-dom";


const WhatWeDo = () => {
    return (
        <div>
            <Header />
            <div className="header">
                <h1 className="idea-title">
                    What We Do.                
                </h1>
            </div>
            <div className={classNames(styles.gradientBox,"gradient-box")}>
                <div className="your-ideas-menu-bar">
                </div>
            </div>
            <article className={styles.container}>
                <div className={styles.row}>
                    <div className={styles.item}>
                        <div className={styles.itemHeading}>
                            <img className={styles.itemIcon} src={productDevLogo} alt="product-development-icon" />
                            <h3>New Product Development</h3>
                            <div className={styles.sectionIntroText}>Turn your ideas into reality. </div>
                        </div>
                        <section className={styles.sectionContent}>
                            We work with you to understand your challenges and design a straightforward path that provides value to your company and a direct line of sight to your product vision producing meaningful experiences..  Our experienced and dedicated team collaborates with you throughout the process, defining the user journey, building the technology,  and delivering world class applications.
                        </section>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.itemHeading}>
                            <img className={styles.itemIcon} src={appRescueLogo} alt="application-triage-and-resuce-icon" />
                            <h3>Application Triage and Rescue</h3>
                            <div className={styles.sectionIntroText}>Rescue your outdated or abandoned applications. </div>
                        </div>
                        <section className={styles.sectionContent}>
                            We will work with you to triage and recover products if and when the current development team has abandoned the project, is not working out, or needs help modernizing.  We work with a variety of programming languages, and take on legacy code when there is no remaining in-house knowledge or documentation.  Our team will keep your product going, expand functionality, and upgrade without you needing to throw it away and start from scratch.
                        </section>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.item}>
                        <div className={styles.itemHeading}>
                            <img className={styles.itemIcon} src={extendingTeamsLogo} alt="enhancing-and-extending-teams-icon" />
                            <h3>Enhancing and Extending Teams</h3>
                            <div className={styles.sectionIntroText}>Enhance, extend, and grow your existing team quickly. </div>
                        </div>
                        <section className={styles.sectionContent}>
                            Meet tight deadlines, have elasticity to scale up and down the team size as needed, and use flexible fractional resources with specific skill sets. Our developers are all located in North America and will jointly work as part of your teams with your process and tools.
                        </section>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.itemHeading}>
                            <img className={styles.itemIcon} src={mentoringTeamsLogo} alt="mentoring-teams-icon" />
                            <h3>Mentoring Teams</h3>
                            <div className={styles.sectionIntroText}>Make your teams stronger, happier, and more effective. </div>
                        </div>
                        <section className={styles.sectionContent}>
                           Make your teams stronger, happier, and more effective.  We provide technical leadership and mentoring through fractional roles.  Grow individuals on your team through design reviews, code reviews, and hands on troubleshooting.   We will help your team improve processes, code quality, and improve the quality deliverables and fix difficult technical problems, while coaching your team.
                        </section>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.item}>
                        <div className={styles.itemHeading}>
                            <img className={styles.itemIcon} src={devOpsLogo} alt="devOps-icon" />
                            <h3>DevOps</h3>
                            <div className={styles.sectionIntroText}>Fully automate your deployments and cloud infrastructure. </div>
                        </div>
                        <section className={styles.sectionContent}>
                            Increase your agility, performance, scalability, and consistency with automated pipelines and infrastructure as code.  We work with all the major cloud environments and CI/CD pipeline tools, setting up environments from scratch, migrating environments and maintaining existing ones while controlling costs.  Our DevOps service is flexible with fractional resources to meet your organizational needs.
                        </section>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.itemHeading}>
                            <img className={styles.itemIcon} src={securityLogo} alt="security-and-compliance-icon" />
                            <h3>Security and Compliance</h3>
                            <div className={styles.sectionIntroText}>Level up the security of your product.</div>
                        </div>
                        <section className={styles.sectionContent}>
                            Gain both an understanding of risk and be prepared for security needs of your enterprise customers.  We perform security reviews, help prepare for security due diligence and security audits.  We develop and implement policies and procedures needed for compliance including PCI, SOC2, and HIPPA.
                        </section>
                    </div>
                </div>
            </article>
            <Footer />
        </div>)
};

export default WhatWeDo;


