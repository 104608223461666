import Footer from "Layout/Footer";
import Header from "Layout/Header";
import React from "react";
import styles from "./WhoWeAre.module.scss";
import ellefson from "../../assets/images/headshots/chris_headshot.jpg";
import ellefsonBack from "../../assets/images/headshots/chris_headshot_back.jpg";
import elcombe from "../../assets/images/headshots/christopher_headshot.jpg";
import elcombeBack from "../../assets/images/headshots/christopher_headshot_back.jpg";
import michelle from "../../assets/images/headshots/michelle_headshot.jpg";
import michelleBack from "../../assets/images/headshots/michelle_headshot_back.jpg";
import daniel from "../../assets/images/headshots/daniel_headshot.png";
import danielBack from "../../assets/images/headshots/daniel_headshot_back.jpg";
import alessio from "../../assets/images/headshots/alessio_headshot.png";
import alessioBack from "../../assets/images/headshots/alessio_headshot_back.jpg";
import nichie from "../../assets/images/headshots/nichie_headshot.png";
import nichieBack from "../../assets/images/headshots/nichie_headshot_back.jpg";
import alan from "../../assets/images/headshots/alan_headshot.jpg";
import alanBack from "../../assets/images/headshots/alan_headshot_back.png";
import matthew from "../../assets/images/headshots/matthew_headshot.png";
import matthewBack from "../../assets/images/headshots/matthew_headshot_back.png";
import jyoti from "../../assets/images/headshots/jyoti_headshot.png";
import jyotiBack from "../../assets/images/headshots/jyoti_headshot_back.png";
import lea from "../../assets/images/headshots/lea_headshot.jpeg";
import leaBack from "../../assets/images/headshots/lea_headshot_back.png";
import pavi from "../../assets/images/headshots/pavi_headshot.jpg";
import paviBack from "../../assets/images/headshots/pavi_headshot_back.png";
import classNames from "classnames";

function WhoWeAre() {
  return <div>
  <Header/>
      <div className="header">
          <h1 className="idea-title">
              Our Team.
          </h1>
      </div>
      <div className="team-content-container">
      {/* <div class="team-blurb-container">
          <div class="team-blurb left"></div>
          <div class="team-blurb">
              At Ollon, we see technology as a means to an end: an enabler for your ideas. Our role is to conceive and build the right technology to bring your vision to reality. Through our architecture leadership, you are assured a robust, end-to-end solution -- from system integration to creative design—that meets and exceeds your expectations.
          </div>
      </div> */}
      <div className="ceo-box">
          <div className={classNames(styles.ceoDescription, "ceo-description")}>
              <div className={classNames(styles.ceoName, "ceo-name")}><span className="f--avenir-heavy">Chris Ellefson</span> <br/></div>
              <div className={styles.ceoImageContainer}>
                <img className={classNames(styles.headshotFront, styles.ceoImage)} src={ellefson} alt=""/>
                <img className={classNames(styles.headshotBack, styles.ceoImageBack)} src={ellefsonBack} alt=""/>
              </div>
          </div>
          <div className="ceo-description ceo-blurb">Chris is a technology leader with twenty years of software development experience. As a CTO and software architect, he brings a proven track record of building innovative technology solutions.
          <br/><br/>Skilled at solving complex, technical problems with a pragmatic and results-focused mindset. He brings breadth and depth of technology expertise to each project - together with a patient, client-centric approach. Chris holds a B.Sc. in Computer Science from MIT and an M.Sc. in Computer Science from the University of British Columbia.</div>
      </div>
      <div className={styles.teamContainer}>
          <div className={styles.teamDescription}>
            <div className={styles.teamDescriptionText}>
                Our approach is consultative and collaborative. We partner with our clients in order to understand their ideas, goals and objectives.
            </div>
          </div>
          <div className={styles.teamMemberContainer}>
              <div className={styles.teamMember}>
                  <img className={styles.headshotFront} src={elcombe} alt=""/>
                  <img className={styles.headshotBack} src={elcombeBack} alt=""/>
                  <div className={styles.teamName}>Chris Elcombe <br/> <span className={styles.teamPosition}></span></div>
              </div>
              <div className={styles.teamMember}>
                  <img className={styles.headshotFront} src={michelle} alt=""/>
                  <img className={styles.headshotBack} src={michelleBack} alt=""/>
                  <div className={styles.teamName}>Michelle Chen <br/> <span className={styles.teamPosition}></span></div>
              </div>
              <div className={styles.teamMember}>
                  <img className={styles.headshotFront} src={daniel} alt=""/>
                  <img className={styles.headshotBack} src={danielBack} alt=""/>
                  <div className={styles.teamName}>Daniel Martinet<br/> <span className={styles.teamPosition}></span></div>
              </div>
              <div className={styles.teamMember}>
                  <img className={styles.headshotFront} src={alessio} alt=""/>
                  <img className={styles.headshotBack} src={alessioBack} alt=""/>
                  <div className={styles.teamName}>Alessio Ianella <br/> <span className={styles.teamPosition}></span></div>
              </div>
              <div className={styles.teamMember}>
                  <img className={styles.headshotFront} src={nichie} alt=""/>
                  <img className={styles.headshotBack} src={nichieBack} alt=""/>
                  <div className={styles.teamName}>Nichie Enriquez<br/> <span className={styles.teamPosition}></span></div>
              </div>
              <div className={styles.teamMember}>
                  <img className={styles.headshotFront} src={alan} alt=""/>
                  <img className={styles.headshotBack} src={alanBack} alt=""/>
                  <div className={styles.teamName}>Alan Wong<br/> <span className={styles.teamPosition}></span></div>
              </div>
              <div className={styles.teamMember}>
                  <img className={styles.headshotFront} src={matthew} alt=""/>
                  <img className={styles.headshotBack} src={matthewBack} alt=""/>
                  <div className={styles.teamName}>Matthew Buie<br/> <span className={styles.teamPosition}></span></div>
              </div>
              <div className={styles.teamMember}>
                  <img className={styles.headshotFront} src={jyoti} alt=""/>
                  <img className={styles.headshotBack} src={jyotiBack} alt=""/>
                  <div className={styles.teamName}>Jyoti Yennam<br/> <span className={styles.teamPosition}></span></div>
              </div>
              <div className={styles.teamMember}>
                  <img className={styles.headshotFront} src={lea} alt=""/>
                  <img className={styles.headshotBack} src={leaBack} alt=""/>
                  <div className={styles.teamName}>Lea Cerron<br/> <span className={styles.teamPosition}></span></div>
              </div>
              <div className={styles.teamMember}>
                  <img className={styles.headshotFront} src={pavi} alt=""/>
                  <img className={styles.headshotBack} src={paviBack} alt=""/>
                  <div className={styles.teamName}>Pavi Pathmarajah<br/> <span className={styles.teamPosition}></span></div>
              </div>
          </div>
      </div>
      <div className="testimonials">
              <div className="testimonial-container">
                  <div className="half-container ceo-description ceo-blurb">
                  “Chris and his team were instrumental in building and rebuilding all of our technology. I have never met a software architect with such tremendous range – able to handle heavy-duty algorithmic work, UI development, mobile apps (iOS and Android) – and even artificial intelligence.”
                  <br/><br/>
                  Ron Estey - CEO - April Age
                  </div>
              </div>
              <div className="testimonial-container">
                  <div className="half-container"></div>
                  <div className="half-container ceo-description ceo-blurb">
                  “I’ve worked with a lot of developers who don’t care and don’t listen. They kind of deliver what they think you are asking for -- but it isn’t! That has never happened with Ollon. They are detail oriented, great listeners – and they understand exactly what you want.  When I ask for a solution – they get it, and they fix it. That’s a big deal. I love them so much.”
                  <br/><br/>
                  Sion McCormick - Support Manager, Digital Director - Ivory Digital</div>
              </div>
      </div>
      </div>
      <Footer/>
  </div>;
}

export default WhoWeAre;
