import Footer from "Layout/Footer";
import Header from "Layout/Header";
import React from "react";

export const Careers = () => {
  return <div>
  <Header/>
  <div className="header">
      <h1 className="idea-title">
          We're looking for good people.
      </h1>
  </div>
  <div className="gradient-box">
      <div className="your-ideas-menu-bar">
      We are a boutique software development company that partners with startups and entrepreneurs that have great ideas. We provide the technical expertise to bring these websites, mobile apps, and software to amazing reality.
      <br/><br/>As a result of our success, our team is growing and we are always open to add to the team. Contact us at<a className="link-white" href="mailto:hello@ollon.ca">hello@ollon.ca.</a></div>
  </div>
<div className="job-container">
  <div className="job">
      <h3>Dev-Ops</h3>
      <div className="dev-ops">We are looking for a recent graduate to join our team as a Junior DevOps  Engineer. As a member of our team you will have access to a a diverse  team that is always ready and willing to help you learn and solve  problems. You'll be trusted to own environments, tackle complex  problems, and have the opportunity to work on and learn a variety of  different technologies while we provide you with the resources needed to  pick up new concepts and technologies quickly.</div>
      <ul className="requirements link-grey">
          <li>○ Cloud:  AWS, Azure, Terraform</li>
          <li>○ Containers:  Docker, AWS ECS, Azure Container Instances</li>
          <li>○ CI/CD Pipelines:  Bitbucket, AWS, Github</li>
          <li>○ Cloud Operations: Monitoring, Central logging, Security scans</li>
          <li>○ Scripting: Python, Bash, JavaScript</li>
          <li>○ Integrations:  SSO,  MS Dynamics,  Power BI</li>
          <li>○ Database:  MySQL,  PostreSQL,  MS SQL</li>
      </ul>
  </div>
  <div className="job">
      <h3>Full Stack</h3>
      <div className="fullStack">We are looking for a versatile software developer to work on our team.   As a member of our team, you'll be able to own large features, tackle complex problems,and have the opportunity to work on and learn a variety of different technologies</div>
      <ul className="requirements link-grey">
          <li>○ Web Frontend: ReactJS,  AnuglarJS</li>
          <li>○ API backend:  NodeJS, C#, Python, PHP, Ruby</li>
          <li>○ Mobile:  ReactNative, iOS (Swift Objective C), Android (Java, Kotlin)</li>
          <li>○ A willingness to be agile and nimble in a startup environment.</li>
          <li>○ DevOps:  Docker, Terraform, AWS, Azure, CI/CD Pipelines</li>
      </ul>
  </div>
  <div className="job">
      <h3>Qualifications</h3>
      <ul className="requirements link-grey">
          <li>○ Experience building and maintaining Saas environments in the Cloud</li>
          <li>○ Ability to produce high quality, simple, and elegant solutions to challenging problems.</li>
          <li>○ Operates with a growth mindset, and demonstrates an appetite for learning new technologies.</li>
          <li>○ Ability to analyze problems and provide technical solutions.</li>
          <li>○ A willingness to be agile and nimble in a startup environment.</li>
          <li>○ An openness to engage with clients</li>
          <li>○ University degree in Computer Science, Computer Engineering, or equivalent</li>
      </ul>
  </div>
  <div className="job">
      <h3>The Perks</h3>
      <ul className="requirements link-grey">
          <li>○ Flexible work hours and working from home options.</li>
          <li>○ Health, dental and life insurance.</li>
          <li>○ Corporate rock climbing membership at Basecamp Climbing.</li>
          <li>○ Board Game and RPG Nights.</li>
      </ul>
  </div>
</div>
<Footer/>
</div>;
};
