import React,  { FC, useEffect } from "react";
import { AppProvider, useSetAppState, useSetUserState } from 'context/index';
import { Routing } from './routing';

import "./App.scss";

const useGetAuth = async () => {
  const setAppState = useSetAppState();
  const setUserState = useSetUserState();
  useEffect(() => {
    const fetchData = async () => {
      setAppState({ Authenticated: false, Authenticating: false });
      
    };

    fetchData();
  }, [setAppState, setUserState]);
};

const App: FC = () => {
  // update User Info
  useGetAuth();

  return (
    <>
      <AppProvider>
          <Routing />
      </AppProvider>
    </>
  );
};

export default App;
