import { useState } from "react";
import Homepage from "Layout/Home";
import styles from "./HomeStyles.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import ollonLogo from "../../assets/svgs/ollon_svg.svg"

export const Home = () => {

    const [toggleViewTop, setToggleViewTop] = useState(true);
    const [toggleViewBottom, setToggleViewBottom] = useState(false);

    function someFunc() {
        setToggleViewTop(!toggleViewTop);
        setToggleViewBottom(!toggleViewBottom);
    }


    const topViewClasses = classNames(
        styles.indexBody,
        styles.background,
        styles.positionAbsolute,
        (!toggleViewTop ? styles.activePageTop : styles.inactivePageBottom)
    );

    const bottomViewClasses = classNames(
        styles.indexBody,
        styles.background,
        styles.positionAbsolute,
        (!toggleViewTop ? styles.activePageBottom : styles.inactivePageTop)
    );

    return (
        <Homepage>
            <div className={styles.homeBody}>
                <div className={topViewClasses}>
                    <div className={styles.indexTop}>
                        <div className={styles.indexTopBlurb}>
                            <p className={styles.indexTopText}>We are a software development company that creates custom solutions to bring your ideas to life.
                                From e-commerce, mobile and web applications to IoT and AI, our expertise is deep. Taking a highly consultative approach, we listen to your needs and then harness the right technology to realize your vision.
                                <Link className="link-grey" to="/HowWeDoIt">See how we do it</Link></p>
                        </div>
                        <div className={styles.indexTopSpace}> </div>
                        <div className={styles.indexTopImage}>
                            <img className={styles.indexTopImageSize} src={ollonLogo} alt="Ollon Logo" />
                        </div>
                    </div>
                    <div className={styles.indexBottom}>
                        <div className={styles.indexBottomBlurb}>
                            <p className={styles.indexBottomText}>
                                We're looking for<Link className="link-white" to="/Careers">good people.</Link>
                            </p>
                            <p className={styles.indexBottomAddress}>
                                <a className="link-grey" href="mailto:hello@ollon.ca">hello@ollon.ca</a>
                            </p>
                        </div>
                    </div>
                    <div className={styles.arrowTop} onClick={someFunc}><i className="up"></i></div>
                </div>
                {/*</div><div id="back-side" className={"index-body background back-side position-absolute " + (isHomeViewTop ? "inactive-page-bottom" : "active-page-bottom")}>*/}
                <div className={classNames(bottomViewClasses, styles.bottomViewWrapper)}>
                    <div className={styles.arrowBottom} onClick={someFunc}><i className="down"></i></div>
                    <div className={styles.bottomContainer}>
                        <div className={styles.headingContainer}>
                            <div className={styles.titleText}>
                                Big idea seeking
                                <br />a <span className={styles.fontAvenirLight}></span>simple solution?
                            </div>
                            <div className={styles.titleText}>
                                Need the <span className={styles.fontAvenirLight}>right technology</span> to
                                <br />drive your product development?
                            </div>
                            <div className={styles.titleText}>
                                Complex project,
                                <br />short <span className={styles.fontAvenirLight}>timelines</span>?
                            </div>
                        </div>
                        <div className={styles.bottomContentContainer}>
                            <div><img className={styles.indexBottomImage} src={ollonLogo} alt="Ollon Logo" /></div>
                            <div className={styles.buttonContainer}>
                                <div>
                                    <Link className={styles.buttonOrange} to="/WhatWeDo">See what we do </Link>
                                </div>
                                <div>
                                    <Link className={styles.buttonOrange} to="/WhoWeAre">Get to know our team</Link>
                                </div>
                                <div>
                                    <Link className={styles.buttonOrange} to="/HowWeDoIt">See what we've done</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Homepage>
    );
};
