import styles from "./styles.module.scss";

// Footer for all pages
// Dumb component, so use stateless Function declaration
// no props
const Footer = () => {
  return (
    <div className={styles.yourIdeas}>
        <div className={styles.footerText}>
            <p className={styles.copyRight}>© Ollon 2021. All rights reserved.</p>
        </div>
        <div className={styles.footerRight}>
          hello@ollon.com
        </div>
    </div>
  );
};

export default Footer;
