import React, {useState} from 'react';
import Footer from "Layout/Footer";
import Header from "Layout/Header";
import styles from "./HowWeDoIt.module.scss";
import feastImage from "../../assets/images/pngs/feast_demo.jpg"
import aprilImage from "../../assets/images/pngs/april_demo.jpg"
import ballotImage from "../../assets/images/pngs/ballotready_demo.jpg"
import deepImage from "../../assets/images/pngs/deeplearning_demo.png"
import liquidImage from "../../assets/images/pngs/arte_demo.jpg"
import illestevaImage from "../../assets/images/pngs/illisteva_demo.jpg"
import eiveyImage from "../../assets/images/pngs/eivey_demo.jpg"
import carnivalImage from "../../assets/images/pngs/tcc_demo.jpg"
import alconeImage from "../../assets/images/pngs/alcone_demo.jpg"
import kooboodleImage from "../../assets/images/pngs/kooboodle_demo.jpg"


const HowWeDoIt  = () => {

  const [isActiveFeast, setIsActiveFeast] = useState(false);
  const [isActiveApril, setIsActiveApril] = useState(false);
  const [isActiveBallot, setIsActiveBallot] = useState(false);
  const [isActiveDeep, setIsActiveDeep] = useState(false);
  const [isActiveLiquid, setIsActiveLiquid] = useState(false);
  const [isActiveIllesteva, setIsActiveillesteva] = useState(false);
  const [isActiveEivey, setIsActiveEivey] = useState(false);
  const [isActiveCarnival, setIsActiveCarnival] = useState(false);
  const [isActiveAlcone, setIsActiveAlcone] = useState(false);
  const [isActiveKooboodle, setIsActiveKooboodle] = useState(false);

  return(
  <div>
  <Header/>
  <div className="header">
      <h1 className={styles.ideasTitle}>
          Your ideas, brought to life.
      </h1>        
      <div className="gradient-box">
          <div className="your-ideas-menu-bar">We are proud to have worked on a myriad of successful technology projects – from custom e-commerce sites and mobile apps to complex artificial intelligence and leading-edge development initiatives. The companies we serve span many industries: retail, research, health, entertainment, beauty, government, finance, professional services marketing communications and more.</div>
      </div>
  </div>
  <React.Fragment>
    <div className={styles.accordion}>
      <div className="row">
        <div className={styles.accordionTitle} onClick={() => setIsActiveFeast(!isActiveFeast)}>
          <div className={styles.title}><h2>FEAST</h2></div>
          <div className={styles.platforms}><p>             Mobile, Web, API </p></div>
          <div className={styles.platform}></div>
        </div>
        {isActiveFeast && <img className={styles.columnLeft} src={feastImage} alt=""/>}
        <div className={styles.columnRight}>
          {isActiveFeast && <div className={styles.accordionContent}>
            Delivering fresh meals fast via mobile & web
          </div>}
          {isActiveFeast && <div className={styles.accordionIdeaTitle}><h4>Idea: </h4></div>}
          {isActiveFeast && <div className={styles.accordionIdeaText}>
            In 2016, the founders of Feast, a Toronto food services business, had a vision: fresh locally-sources meals delivered quickly.
          </div>} 
          {isActiveFeast && <div className={styles.accordionIdeaTitle}><h4>Solution: </h4></div>}
          {isActiveFeast && <div className={styles.accordionIdeaText}>
            Ollon built an end-to-end system that allowed their customers to order fresh meals via mobile or web – and receive fast delivery. Our custom solution also made it easy for Feast to automate inventory and delivery management for seamless operations.
          </div>}
          {isActiveFeast && <div className={styles.accordionIdeaTitle}><h4>Results: </h4></div>}
          {isActiveFeast && <div className={styles.accordionIdeaText}>
            Feast went live in just 6 months. Within the first three months, 300 meals on average were being successfully delivered each lunch hour— most within just 15 minutes of ordering. The ease and efficiency of the new e-delivery service sparked glowing reviews.
          </div>}
          {isActiveFeast && <div className={styles.accordionIdeaTitle}><h4>Technologies: </h4></div>}
          {isActiveFeast && <div className={styles.accordionIdeaText}>
            NodeJS,  C#, Xamarin,  MySql,  Contentful, HTML/CSS, Javascript
          </div>}
          </div>
          {isActiveFeast && <div className={styles.base}>.</div>}
      </div>

      <div className="row">
        <div className={styles.accordionTitle} onClick={() => setIsActiveApril(!isActiveApril)}>
          <div className={styles.title}><h2>AprilAge</h2></div>
          <div className={styles.platforms}><p>Web, Mobile, Machine Learning, Exhibits</p></div>
          <div className={styles.platform}></div>
        </div>
        {isActiveApril && <img className={styles.columnLeft} src={aprilImage} alt=""/>}
        <div className={styles.columnRight}>
          {isActiveApril && <div className={styles.accordionContent}>
            State-of-the-art aging technology shows people their future face
          </div>}
          {isActiveApril && <div className={styles.accordionIdeaTitle}><h4>Idea: </h4></div>}
          {isActiveApril && <div className={styles.accordionIdeaText}>
            AprilAge, a facial aging software company, wanted to inspire the world to adopt a healthier lifestyle. They envisioned a tool to show people how they would age based on different lifestyle factors – including smoking and obesity
          </div>}
          {isActiveApril && <div className={styles.accordionIdeaTitle}><h4>Solution: </h4></div>}
          {isActiveApril && <div className={styles.accordionIdeaText}>
            Ollon was the chief architect during development of a predictive facial aging engine using machine learning techniques. We built applications on multiple platforms including iOS, Android, Windows, Web and Kiosk, and delivered a solution that transformed the vision into reality:  a web service that allows users to access the APRIL® Face Aging experience in a convenient and private way. Later in the project, our team helped April strengthen their offering with more robust technology and more features – expanding their product line with a body morphing engine.
          </div>}
          {isActiveApril && <div className={styles.accordionIdeaTitle}><h4>Results: </h4></div>}
          {isActiveApril && <div className={styles.accordionIdeaText}>
            Today, April® is used in 84 countries by insurance companies, health organizations, government agencies, science centres, and other organizations wishing to provide a highly engaging member or patient experience. It has been proven in independent research and controlled studies that the use of APRIL®'s predictive visualization software now increases the number of people who quit smoking by more than 10 times that of the control group.
          </div>}
          {isActiveApril && <div className={styles.accordionIdeaTitle}><h4>Technologies: </h4></div>}
          {isActiveApril && <div className={styles.accordionIdeaText}>
            C++,  C#, Xamarin,  Qt,  OpenGL, OpenCV,   HTML/CSS, Javascript
          </div>}
          {isActiveApril && <div className={styles.accordionTestText}>
            “Chris and his team were instrumental in building and rebuilding all of our technology. I have never met a software architect with such tremendous range – able to handle heavy-duty algorithmic work, UI development, mobile apps (iOS and Android) – and even artificial intelligence.”
          </div>}
          {isActiveApril && <div className={styles.accordionTestText}>
            Ron Estey - CEO - April Age
          </div>}
          </div>
          {isActiveApril && <div className={styles.base}>.</div>}
      </div>
      <div className="row">
        <div className={styles.accordionTitle} onClick={() => setIsActiveBallot(!isActiveBallot)}>
          <div className={styles.title}><h2>Ballot Ready</h2></div>
          <div className={styles.platforms}><p>Web, API </p></div>
          <div className={styles.platform}></div>
        </div>
        {isActiveBallot && <img className={styles.columnLeft} src={ballotImage} alt=""/>}
        <div className={styles.columnRight}>
          {isActiveBallot && <div className={styles.accordionContent}>
            A data-driven solution to strengthen democracy
          </div>}
          {isActiveBallot && <div className={styles.accordionIdeaTitle}><h4>Idea: </h4></div>}
          {isActiveBallot && <div className={styles.accordionIdeaText}>
            Ballot Ready was on a mission to create a more informed electorate. They wanted to strengthen democracy by helping voters vote in a more informed fashion all the way down the ballot.
          </div>} 
          {isActiveBallot && <div className={styles.accordionIdeaTitle}><h4>Solution: </h4></div>}
          {isActiveBallot && <div className={styles.accordionIdeaText}>
            With the US 2016 election looming – and only 5 months to  complete the project once funding was received -- Ollon provided the development muscle needed to collect, catalogue and provide easy voter access to candidate information. We augmented the Ballot-Ready internal team with three seasoned developers to meet these aggressive targets. Work commenced on an engine that automated many aspects of data collection – including the gathering and sorting of unstructured data from disparate sources. Candidate information was rapidly pulled together into a consistent, structured and geography-specific format that was easy to access by voters needing insight to inform their decisions.
          </div>}
          {isActiveBallot && <div className={styles.accordionIdeaTitle}><h4>Results: </h4></div>}
          {isActiveBallot && <div className={styles.accordionIdeaText}>
            For the 2016 US election, Ollon helped Ballot ready collect, organize and provide access to detailed information on more than 20,000 candidates within challenging timelines. The Ballot Ready website attracted more than ¾ of a million visitor-voters prior to the election.
          </div>}
          {isActiveBallot && <div className={styles.accordionIdeaTitle}><h4>Technologies: </h4></div>}
          {isActiveBallot && <div className={styles.accordionIdeaText}>
            NodeJs, MySql, Amazon Mechanical Turk, Ruby
          </div>}
          </div>
          {isActiveBallot && <div className={styles.base}>.</div>}
      </div>
      <div className="row">
        <div className={styles.accordionTitle} onClick={() => setIsActiveDeep(!isActiveDeep)}>
          <div className={styles.title}><h2>Deep Learni.ng</h2></div>
          <div className={styles.platforms}><p>Web, Design </p></div>
          <div className={styles.platform}></div>
        </div>
        {isActiveDeep && <img className={styles.columnLeft} src={deepImage} alt=""/>}
        <div className={styles.columnRight}>
          {isActiveDeep && <div className={styles.accordionContent}>
            Leading-edge artificial intelligence app delivered ahead of schedule
          </div>}
          {isActiveDeep && <div className={styles.accordionIdeaTitle}><h4>Idea: </h4></div>}
          {isActiveDeep && <div className={styles.accordionIdeaText}>
            Deep Learni.ng, a pioneering artificial intelligence startup, wanted to take machine learning to the next level with an easy-to-use artificial intelligence tool. They aimed to expedite and enrich the work of data scientists working with large banks.
          </div>} 
          {isActiveDeep && <div className={styles.accordionIdeaTitle}><h4>Solution: </h4></div>}
          {isActiveDeep && <div className={styles.accordionIdeaText}>
            Ollon brought its seasoned developers to work alongside the team at Deep Learn.ing. Together, they created the UX and visual design and built the web application for a ground-breaking artificial intelligence product.
          </div>}
          {isActiveDeep && <div className={styles.accordionIdeaTitle}><h4>Results: </h4></div>}
          {isActiveDeep && <div className={styles.accordionIdeaText}>
            Through team augmentation, we accelerated the development of this next generation machine learning application, bringing it to launch months ahead of schedule.
          </div>}
          {isActiveDeep && <div className={styles.accordionIdeaTitle}><h4>Technologies: </h4></div>}
          {isActiveDeep && <div className={styles.accordionIdeaText}>
            ReactJS,  HTML/CSS
          </div>}
          {isActiveDeep && <div className={styles.accordionTestText}>
          “Ollon came well reviewed and was excellent in how it handled a complex project that was not overly planned. We received complete statements of work, competitive pricing and developers who sped up the development process putting us at least one to two quarters ahead.”
          </div>}
          {isActiveDeep && <div className={styles.accordionTestText}>
          Victor Andros - Chief Technology Officer Elevating - Deep Learning
          </div>}
          </div>
          {isActiveDeep && <div className={styles.base}>.</div>}
      </div>
      <div className="row">
        <div className={styles.accordionTitle} onClick={() => setIsActiveLiquid(!isActiveLiquid)}>
          <div className={styles.title}><h2>Liquid Cinema Arte 360</h2></div>
          <div className={styles.platforms}><p>Mobile </p></div>
          <div className={styles.platform}></div>
        </div>
        {isActiveLiquid && <img className={styles.columnLeft} src={liquidImage} alt=""/>}
        <div className={styles.columnRight}>
          {isActiveLiquid && <div className={styles.accordionContent}>
            Delivering virtual reality video content via mobile
          </div>}
          {isActiveLiquid && <div className={styles.accordionIdeaTitle}><h4>Idea: </h4></div>}
          {isActiveLiquid && <div className={styles.accordionIdeaText}>
            Deep 360, a pioneering producer of 360 degree video, wanted to bring engaging and immersive virtual reality video content to traditional media companies.
          </div>} 
          {isActiveLiquid && <div className={styles.accordionIdeaTitle}><h4>Solution: </h4></div>}
          {isActiveLiquid && <div className={styles.accordionIdeaText}>
            In 2015, Ollon developed the first iOS application using Liquid’s platform. The mobile app worked in conjunction with the platform to enable users to play 360 degree virtual reality videos and iphones.
          </div>}
          {isActiveLiquid && <div className={styles.accordionIdeaTitle}><h4>Results: </h4></div>}
          {isActiveLiquid && <div className={styles.accordionIdeaText}>
            Through the new iOS app, large media companies could bring 360 degree videos to their customer base. The development innovation also allowed Deep 360 to deliver successfully for its first major client, Art 360, who adopted the liquid Cinema platform. From this first key success, the company gained credibility and a track record from which it was able to grow its base.
          </div>}
          {isActiveLiquid && <div className={styles.accordionIdeaTitle}><h4>Technologies: </h4></div>}
          {isActiveLiquid && <div className={styles.accordionIdeaText}>
            Objective C, OpenGL
          </div>}
          </div>
          {isActiveLiquid && <div className={styles.base}>.</div>}
      </div>
      <div className="row">
        <div className={styles.accordionTitle} onClick={() => setIsActiveillesteva(!isActiveIllesteva)}>
          <div className={styles.title}><h2>Illesteva</h2></div>
          <div className={styles.platforms}><p>Web </p></div>
          <div className={styles.platform}></div>
        </div>
        {isActiveIllesteva && <img className={styles.columnLeft} src={illestevaImage} alt=""/>}
        <div className={styles.columnRight}>
          {isActiveIllesteva && <div className={styles.accordionContent}>
          Site for sore eyes:  a state-of-the-art e-commerce website for fashionable eyewear
          </div>}
          {isActiveIllesteva && <div className={styles.accordionIdeaTitle}><h4>Idea: </h4></div>}
          {isActiveIllesteva && <div className={styles.accordionIdeaText}>
          Illesteva, a handmade Italian eyewear company, wanted to dramatically accelerate online sales of their high-end designer glasses.
          </div>} 
          {isActiveIllesteva && <div className={styles.accordionIdeaTitle}><h4>Solution: </h4></div>}
          {isActiveIllesteva && <div className={styles.accordionIdeaText}>
          Ollon built a state-of-the-art e-commerce website on Magento that allowed  customers to upload 9 types of prescriptions (such as polarized lenses) – and add them to the base eyewear product at checkout.
          </div>}
          {isActiveIllesteva && <div className={styles.accordionIdeaTitle}><h4>Results: </h4></div>}
          {isActiveIllesteva && <div className={styles.accordionIdeaText}>
          The development work was completed seamlessly – with no client or customer interruptions.  Customers began enthusiastically using the new prescription functionality right away. The sleek and consumer-friendly retail site that Ollon built made it appealing and easy for consumers to order high-end glasses online.
          </div>}
          {isActiveIllesteva && <div className={styles.accordionIdeaTitle}><h4>Technologies: </h4></div>}
          {isActiveIllesteva && <div className={styles.accordionIdeaText}>
          PHP, Magento, HTML/CSS
          </div>}
          {isActiveIllesteva && <div className={styles.accordionTestText}>
          “I’ve worked with a lot of developers who don’t care and don’t listen. They kind of deliver what they think you are asking for -- but it isn’t! That has never happened with Ollon. They are detail oriented, great listeners – and they understand exactly what you want.  When I ask for a solution – they get it, and they fix it. That’s a big deal. I love them so much.”
          </div>}
          {isActiveIllesteva && <div className={styles.accordionTestText}>
          Sion McCormick - Support Manager, Digital Director - Ivory Digital
          </div>}
          </div>
          {isActiveIllesteva && <div className={styles.base}>.</div>}
      </div>
      <div className="row">
        <div className={styles.accordionTitle} onClick={() => setIsActiveEivey(!isActiveEivey)}>
          <div className={styles.title}><h2>Eivey</h2></div>
          <div className={styles.platforms}><p>Web </p></div>
          <div className={styles.platform}></div>
        </div>
        {isActiveEivey && <img className={styles.columnLeft} src={eiveyImage} alt=""/>}
        <div className={styles.columnRight}>
          {isActiveEivey && <div className={styles.accordionContent}>
          Web application brings to life dream of ‘pre-loved’ clothes marketplace
          </div>}
          {isActiveEivey && <div className={styles.accordionIdeaTitle}><h4>Idea: </h4></div>}
          {isActiveEivey && <div className={styles.accordionIdeaText}>
          The founders of Eivey wanted to make fashion more accessible on a budget. They envisioned a marketplace where people could buy and sell pre-loved designer clothing.
          </div>} 
          {isActiveEivey && <div className={styles.accordionIdeaTitle}><h4>Solution: </h4></div>}
          {isActiveEivey && <div className={styles.accordionIdeaText}>
          Through CTO consulting services, Ollon first helped shape the clothes marketplace idea and conceive a cost-effective e-commerce solution to get it rolling. Our developers then proceeded to build a web application based in ShareTribe that enabled pier-to-pier buying and selling of fashion items.
          </div>}
          {isActiveEivey && <div className={styles.accordionIdeaTitle}><h4>Results: </h4></div>}
          {isActiveEivey && <div className={styles.accordionIdeaText}>
          Ollon delivered a creative solution to a complex idea within a limited budget. The website has recently gone live allowing clients to buy and sell their pre-loved clothing online with ease.
          </div>}
          {isActiveEivey && <div className={styles.accordionIdeaTitle}><h4>Technologies: </h4></div>}
          {isActiveEivey && <div className={styles.accordionIdeaText}>
          Ruby, ShareTribe
          </div>}
          {isActiveEivey && <div className={styles.accordionTestText}>
            “We knew what we wanted and Ollon brought the technology to help us realize our vision. The support team is knowledgeable, patient, responsive and they speak with us on a daily basis. The technical side is on point – exactly what we wanted - and we love the fact that working with Ollon is more of a partnership than a client-supplier relationship.”
          </div>}
          {isActiveEivey && <div className={styles.accordionTestText}>
            Laura Sim - Cofounder - Eivey
          </div>}
          </div>
          {isActiveEivey && <div className={styles.base}>.</div>}
      </div>
      <div className="row">
        <div className={styles.accordionTitle} onClick={() => setIsActiveCarnival(!isActiveCarnival)}>
          <div className={styles.title}><h2>Toronto Caribbean Carnival</h2></div>
          <div className={styles.platforms}><p>Web </p></div>
          <div className={styles.platform}></div>
        </div>
        {isActiveCarnival && <img className={styles.columnLeft} src={carnivalImage} alt=""/>}
        <div className={styles.columnRight}>
          {isActiveCarnival && <div className={styles.accordionContent}>
          Beyond the big event: driving multiple revenue streams through an e-commerce and loyalty app
          </div>}
          {isActiveCarnival && <div className={styles.accordionIdeaTitle}><h4>Idea: </h4></div>}
          {isActiveCarnival && <div className={styles.accordionIdeaText}>
          The Toronto Caribbean Carnival dreamed of growing its website from a simple information portal to an e-commerce site with multiple revenue streams.
          </div>} 
          {isActiveCarnival && <div className={styles.accordionIdeaTitle}><h4>Solution: </h4></div>}
          {isActiveCarnival && <div className={styles.accordionIdeaText}>
          After in-depth consultation with the marketing team, Ollon conceived and built a web application leveraging Shopify that smoothly integrated e-commerce functionality and an engaging loyalty program to drive other streams of business. The new app enabled smooth transactions for event ticket sales, band costume sales, travel, hotel sales and more.
          </div>}
          {isActiveCarnival && <div className={styles.accordionIdeaTitle}><h4>Results: </h4></div>}
          {isActiveCarnival && <div className={styles.accordionIdeaText}>
          Ollon delivered a fully-functioning ecommerce solution that integrated ticket sales and a loyalty program – all in less than 45 days.
          </div>}
          {isActiveCarnival && <div className={styles.accordionIdeaTitle}><h4>Technologies: </h4></div>}
          {isActiveCarnival && <div className={styles.accordionIdeaText}>
          Shopify, HTML/CSS
          </div>}
          {isActiveCarnival && <div className={styles.accordionTestText}>
          “Thanks to Ollon, the Carnival has begun to rapidly expand its event business. Through strong technical guidance, speedy development and great vision, Chris’s team delivered our first internal ticket sales solution -- and helped us realize our vision of multiple income streams.”
          </div>}
          {isActiveCarnival && <div className={styles.accordionTestText}>
            Jocelyn Butler - President - Orchestra Marketing (and consultant to the Toronto Caribbean Carnival)
          </div>}
          </div>
          {isActiveCarnival && <div className={styles.base}>.</div>}
      </div>
      <div className="row">
        <div className={styles.accordionTitle} onClick={() => setIsActiveAlcone(!isActiveAlcone)}>
          <div className={styles.title}><h2>Alcone</h2></div>
          <div className={styles.platforms}><p>Web </p></div>
          <div className={styles.platform}></div>
        </div>
        {isActiveAlcone && <img className={styles.columnLeft} src={alconeImage} alt=""/>}
        <div className={styles.columnRight}>
          {isActiveAlcone && <div className={styles.accordionContent}>
          Building a beauty community: an e-commerce & community website for makeup professionals
          </div>}
          {isActiveAlcone && <div className={styles.accordionIdeaTitle}><h4>Idea: </h4></div>}
          {isActiveAlcone && <div className={styles.accordionIdeaText}>
          Alcone, a makeup company, had a vision of an online community. A place where makeup artists could network and share their work to drive engagement and online sales of its makeup products.
          </div>} 
          {isActiveAlcone && <div className={styles.accordionIdeaTitle}><h4>Solution: </h4></div>}
          {isActiveAlcone && <div className={styles.accordionIdeaText}>
          Ollon developed a large-scale solution that brought together Alcone’s e-commerce site (built using Magento, PHP) with a new community networking site (built using Ruby on Rails). The two portals were connected through an API and shared the same log-in for members. Special offers and incentives on the community site would drive sales of makeup on the e-commerce site. In addition, Ollon oversaw a seamless transition of the Alcone Co site to a new hosting solution.
          </div>}
          {isActiveAlcone && <div className={styles.accordionIdeaTitle}><h4>Results: </h4></div>}
          {isActiveAlcone && <div className={styles.accordionIdeaText}>
          Through the skillful building and linking of two disparate websites, Ollon made it possible for Alcone to drive deeper engagement with its brand, increase its proprietary makeup sales, deliver on-demand support to its customers – and ensure all the technology ticked along transparently and without interruption in the background.
          </div>}
          {isActiveAlcone && <div className={styles.accordionIdeaTitle}><h4>Technologies: </h4></div>}
          {isActiveAlcone && <div className={styles.accordionIdeaText}>
          Magento, PHP, Ruby on Rails
          </div>}
          </div>
          {isActiveAlcone && <div className={styles.base}>.</div>}
      </div>
      <div className="row">
        <div className={styles.accordionTitle} onClick={() => setIsActiveKooboodle(!isActiveKooboodle)}>
          <div className={styles.title}><h2>Kooboodle</h2></div>
          <div className={styles.platforms}><p>Mobile, Machine Learning </p></div>
          <div className={styles.platform}></div>
        </div>
        {isActiveKooboodle && <img className={styles.columnLeft} src={kooboodleImage} alt=""/>}
        <div className={styles.columnRight}>
          {isActiveKooboodle && <div className={styles.accordionContent}>
          Social photo sharing platform enhances company offering
          </div>}
          {isActiveKooboodle && <div className={styles.accordionIdeaTitle}><h4>Idea: </h4></div>}
          {isActiveKooboodle && <div className={styles.accordionIdeaText}>
          Clickfree, a backup storage device company, wanted to offer private photo sharing as an exciting value-add for their customer base.
          </div>} 
          {isActiveKooboodle && <div className={styles.accordionIdeaTitle}><h4>Solution: </h4></div>}
          {isActiveKooboodle && <div className={styles.accordionIdeaText}>
          Ollon helped by developing the Kooboodle iOS and Android apps.  Using machine learning, the team developed a face recognition system for photo sharing among friends.
          </div>}
          {isActiveKooboodle && <div className={styles.accordionIdeaTitle}><h4>Results: </h4></div>}
          {isActiveKooboodle && <div className={styles.accordionIdeaText}>
          Ollon augmented the Clickfree internal team with our seasoned developers to meet rigorous targets. In the first three months, we delivered a working prototype the client could showcase at CIS. From there, we went on to build multiple releases, refining and streamlining the product and its user experience based on customer feedback.
          </div>}
          {isActiveKooboodle && <div className={styles.accordionIdeaTitle}><h4>Technologies: </h4></div>}
          {isActiveKooboodle && <div className={styles.accordionIdeaText}>
          C#, Xamarin, OpenCV, Python
          </div>}
          </div>
          {isActiveKooboodle && <div className={styles.base}>.</div>}
      </div>
      <div className={styles.bar}>
          <div><h2>.</h2></div>
        </div>
    </div>
  </React.Fragment>
  <Footer/>
</div>)
};

export default HowWeDoIt;


