import { FC } from 'react';
import ContentWrapper from "./ContentWrapper";

const HomePresentation : FC = ({children}) => {
  return (<div>
    <ContentWrapper>
      {children}
    </ContentWrapper>
  </div>);
};

export default HomePresentation;
