import React, { FC } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { Home } from "pages/Home";
import WhoWeAre from "pages/WhoWeAre";
import HowWeDoIt from "pages/HowWeDoIt";
import { Careers } from "pages/Careers";
import WhatWeDo from "pages/WhatWeDo";

export enum RoutePaths {
  Home = "/",
  WhoWeAre = "/WhoWeAre",
  HowWeDoIt = "/HowWeDoIt",
  Careers = "/Careers",
  WhatWeDo = "/WhatWeDo" 
};

export const Routing: FC = () => {
  return (
    <Router>
      <Switch>
        <Route path={RoutePaths.WhatWeDo}>
          <WhatWeDo />
        </Route>
        <Route path={RoutePaths.WhoWeAre}>
          <WhoWeAre />
        </Route>
        <Route path={RoutePaths.HowWeDoIt}>
          <HowWeDoIt />
        </Route>
        <Route path={RoutePaths.Careers}>
          <Careers />
        </Route>
        <Route path={RoutePaths.Home}>
          <Home />
        </Route>
      </Switch>
    </Router>
  );
};
